type PartnerInfo = {
    imagePath: string;
    website?: string;
    noInvert?: boolean;
};

export default [
    {
        imagePath: "/calypso-logo.svg",
        website: "https://calypsohub.network"
    },
    {
        imagePath: "/dirtroad-logo.svg",
        website: "https://dirtroad.dev"
    },
    {
        imagePath: "/dotg-logo.svg"
    },
    {
        imagePath: "/keepsake-logo.png",
        website: "https://keepsake.gg",
        noInvert: true
    },
    {
        imagePath: "/neuranode-logo.svg",
        website: "https://www.neuranode.io"
    },
    {
        imagePath: "/stellar-gate-logo.png",
        website: "https://stellargate.io"
    },
    {
        imagePath: "/skale-logo.svg",
        website: "https://skale.space"
    },
    {
        imagePath: "/untitled-logo.svg",
        website: "https://untitledplatformer.io"
    }
] satisfies PartnerInfo[];