import './styles.css';

export default function Hero() {
  return (
    <section className="hero-container">
        <div className="hero-section">
            <div className="hero-content">
                <h1 className="hero-slogan">
                    <span className="color-accent">Build.</span>
                    <span className="color-primary">Better.</span>
                    <span className="color-secondary">Faster.</span>
                </h1>
                <p className="hero-description">
                Accelerate your development with cutting-edge tools and resources.
                </p>
                <div className="hero-buttons">
                    <a href="https://console.eidolon.gg" className="hero-button">Get Started</a>
                </div>
            </div>
        </div>
    </section>
  );
};