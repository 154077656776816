
type Props = {
    children: JSX.Element;
    title: string;
    body: string;
}
export default function KeyPoint({
    body,
    children,
    title
}: Props) {
    return (
        <div className="keypoint">
            <div className="keypoint__top">
                <div className="icon-container">
                    {children} {/* Icon */}
                </div>
                <h4>{title}</h4>
            </div>
            <div className="keypoint__body">
                <p>{body}</p>
            </div>
        </div>
    );
}