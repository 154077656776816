import Marquee from 'react-fast-marquee';
import { partners } from '../../../../config';
import './styles.css';

export default function Scroll() {
  return (
    <div className="scroller-container">
        <Marquee
            loop={0}
        >
            {partners.map((partner, index) => (
                <div className="scroller-item" key={index}>
                    <a
                        href={partner.website ?? "#"}
                        target="_blank"
                    >
                        <img
                            className={`${partner.imagePath.split(".")[1]} ${partner.noInvert ? "no-invert" : "invert" }`}
                            src={partner.imagePath}
                            alt={`Image ${index + 1}`}
                        />
                    </a>
                </div>
            ))}
        </Marquee>
    </div>
  );
}
