import { testimonials } from "../../../../config";
import "./styles.css";
import Testimonial from "./Testimonial";

export default function Testimonials() {
    return (
        <section className="testimonials-container">
            <div className="testimonials-container__header">
                <h4>Testimonials</h4>
                <h3>Hear from our customers</h3>
            </div>
            <div className="testimonials-container__list">
                <div className="tcol tcol-0">
                    <Testimonial
                        {...testimonials[0]}
                    />
                    <Testimonial
                        {...testimonials[1]}
                    />
                </div>
                <div className="tcol tcol-1">
                    <Testimonial
                        {...testimonials[2]}
                    />
                    <Testimonial
                        {...testimonials[3]}
                    />
                </div>
                <div className="tcol tcol-2">
                    <Testimonial
                        {...testimonials[4]}
                    />
                    <Testimonial
                        {...testimonials[5]}
                    />
                </div>
            </div>
        </section>
    );
}