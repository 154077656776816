import { FaTools } from "react-icons/fa";
import { FaBookOpenReader, FaCode, FaSatellite, FaUnity } from "react-icons/fa6";
import { MdSecurity } from "react-icons/md";

type KP = {
    icon: JSX.Element;
    title: string;
    body: string;
};

export default [
    {
        title: "Built for Unity",
        body: "Designed for the most popular game development engine in the world",
        icon: <FaUnity />
    },
    {
        title: "Focused on DevX",
        body: "Developer Experience is often underrated when building tools. Not with Eidolon.",
        icon: <FaCode />
    },
    {
        title: "Build. Better. Faster.",
        body: "With a growing suite of compatible packages, Eidolon is your one stop shop for tools that just work.",
        icon: <FaTools />
    },
    {
        title: "Your Satellite Team",
        body: "The Eidolon team builds packages, documentation, and of course games for other teams. Use Eidolon resources like your own.",
        icon: <FaSatellite />
    },
    {
        title: "First Class Docs",
        body: "When building tools for other developers, regardless of experience or skill, documentation should never be an afterthought.",
        icon: <FaBookOpenReader />
    },
    {
        title: "Security cannot be Assumed",
        body: "Both games and game dev tools are full of vulnerabilities. Have your security searched today by the Eidolon Security Team (EST).",
        icon: <MdSecurity />
    }
] satisfies KP[];