import { Footer, Navigation } from "./components";
import HomePage from "./pages/Home";

export function Main() {

  return (
    <div id="main-body">
      <Navigation />
      <section className="wrapper">
        <HomePage />
      </section>
      <Footer />
    </div>
  );
}
