import { pricing } from "../../../../config";
import PricingCard from "./PricingCard";
import "./styles.css";

export default function Pricing() {

    return (
        <section className="pricing-container">
          <div className="pricing-container__top">
              <h4>Pricing</h4>
              <h3>A plan for every project</h3>
          </div>
          <div className="pricing-container__body">
              {pricing.map((plan, index) => (
                  <PricingCard
                      key={index}
                      {...plan}
                  />
              ))}

          </div>
          <div className="pricing-container__notes">
            <p>* represents additional charges outside fo the above</p>
            <p>** new packages may be added to any of the tiers. Pricing subject to change</p>
          </div>
        </section>
    );
}