import keypoints from "./keypoints";
import partners from "./partners";
import { pricing, features } from "./pricing";
import testimonials from "./testimonials";

export {
    keypoints,
    partners,
    pricing,
    features,
    testimonials
}