type Props = {
    name: string;
    role: string;
    quote: string;
}

export default function Testimonial({
    name,
    role,
    quote
}: Props) {
    return (
        <div className="testimonial">
            <div className="testimonial__top">
                <div className="person-info">
                    <h5>{name}</h5>
                    <p>{role}</p>
                </div>
            </div>
            <div className="testimonial__body">
                <p>{quote}</p>
            </div>
        </div>
    );
}