import "./styles.css";

export default function Navigation() {
    return (
        <nav id="navigation">
            <div className="navigation__title">
                <img src="/logo-e.png" alt="Eidolon Gaming" />
                <h1>Eidolon</h1>
            </div>
            <div className="navigation__actions">
                 <a className="link-empty" href="https://docs.eidolon.gg" target="_blank">Docs</a> 
                <a className="link-button"href="https://console.eidolon.gg/auth/register" target="_blank">Build Now</a>
            </div>
        </nav>  
    );
}