import "./styles.css";

export default function Introduction() {
    return (
        <section className="introduction-container">
            <div className="gradient-wrapper">
                <h4>Built By Game Developers</h4>
                <h3>Let Our Packages Solve Your Problems</h3>
                <img src="/ff-1.jpg" alt="placeholder" />
            </div>
        </section>
    );
}