type T = {
    name: string;
    role: string;
    quote: string;
};

export default [
    {
        name: "SKALE Labs",
        role: "Head of Growth",
        quote: "\"Eidolon has elevated our dev experience exponentially while also providing much needed security and peace of mind to our top projects\""
    },
    {
        name: "Infinite Star Studios",
        role: "CEO",
        quote: "\"I love Eidolon due to how quick I can add blockchain interactivity to my Unity games!\""
    },
    {
        name: "Hangry Animals",
        role: "Founder",
        quote: "\"Eidolon has has narrowed the barrier of entry into Web3 principles for our game devs.\""
    },

    {
        name: "BaseSwapGames Lab",
        role: "Founder",
        quote: "\"Eidolon Web3 has proven to be a much better tool the others I've used.\""
    },
    {
        name: "Shadow of Ferenor",
        role: "CEO",
        quote: "\"The Eidolon products and support have made our Web3 integration much easier than anticipated!\""
    },

    {
        name: "Untitled Platformer",
        role: "CEO",
        quote: "\"Since I began using Eidolon, I've cut hundreds of hours off my development time.\""
    },
] satisfies T[];