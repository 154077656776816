type PriceData = {
    title: string;
    description: string;
    price: string;
    features: string[];
    popular?: boolean;
    comingSoon?: boolean;
    actionUrl: string;
}

const pricing = [
    {
        title: 'Starter',
        description: 'For those getting started',
        price: '$0 /mo',
        features: [
            "Community Support",
            "Web3 Packages",
            "Web3 Extensions"
        ],
        actionUrl: "https://console.eidolon.gg"
    },
  {
    title: 'Pro',
    description: 'For indie developers',
    price: '$59 /mo',
    popular: true,
    features: [
        "Everything in Free plus:",
        "White-glove Support",
        "AI Packages",
        "Physics Packages",
        "Utility Packages"
    ],
    actionUrl: "#",
    comingSoon: true
  },
  {
    title: 'Growth & Enterprise',
    description: 'For Studios & Fast Growing Projects',
    price: 'Starts at $999 /mo',
    features: [
        "Everything in Pro plus:",
        "Eidolon Engine",
        "Security Packages",
        "Development Services*",
        "Review Services*",
        "Security Services*",
    ],
    actionUrl: "mailto:sawyer@eidolon.gg?subject=Eidolon Enterprise Request"
  },
] satisfies PriceData[];

export {
    pricing
}