import "./styles.css";

export default function Footer() {
    return (
        <footer>
            <div className="information">
                <div className="eidolon">
                    <img src="/logo-e.png" alt="Eidolon Gaming" />
                    <p>Eidolon</p>
                </div>
                <div className="links">
                    <div className="col">
                        <h6>Links</h6>
                        <a href="https://console.eidolon.gg">Developer Console</a>
                        <a href="https://docs.eidolon.gg">Developer Docs</a>
                        <a href="https://discord.eidolon.gg">Discord</a>
                        <a href="mailto:support@eidolon.gg?subject=Eidolon Support Request">Support</a>
                    </div>
                    <div className="col">
                        <h6>Legal</h6>
                        <a target="_blank" href="https://console.eidolon.gg/legal/eula">EULA</a>
                        <a target="_blank" href="https://console.eidolon.gg/legal/editor-terms">Editor Terms</a>
                        <a target="_blank" href="https://console.eidolon.gg/legal/terms">Terms of Use</a>
                        <a target="_blank" href="https://console.eidolon.gg/privacy">Privacy Policy</a>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <p>Copyright &copy; 2024 <a style={{ textDecoration: "none" }} target="_blank" href="https://dirtroad.dev">Dirt Road Development</a>. All Rights Reserved.</p>
            </div>
        </footer>  
    );
}