type Props = {
	title: string;
	description: string;
	price: string;
	features: string[];
	popular?: boolean;
	comingSoon?: boolean;
	actionUrl: string;
}

export default function PricingCard({
	title,
	description,
	price,
	features,
	popular,
	actionUrl,
	comingSoon
}: Props) {
  return (
    <div className={`pricing-card ${popular ? 'popular' : ''}`}>
	    <h3 className="pricing-title">{title}</h3>
	    <p className="pricing-description">{description}</p>
	    <p className="pricing-price">{price}</p>
	    <ul className="pricing-features">
	      {features.map((feature, index) => (
	        <li
	        	key={index + "-" + feature}
	        	className="pricing-feature"
	        >
	          {feature}
	        </li>
	      ))}
	    </ul>
    	<a href={actionUrl} target="_blank" className="get-started-btn">{comingSoon ? "Coming Soon" : "Get Started"}</a>
    </div>
  );
};