import {
    Hero
} from "../../components";
import Introduction from "./components/Introduction/Introduction";
import KeyPoints from "./components/KeyPoints/KeyPoints";
import Pricing from "./components/Pricing/Pricing";
import Scroll from "./components/Scroll/Scroll";
import Testimonials from "./components/Testimonials/Testimonials";

export default function HomePage() {
    return (
        <>
            <Hero />
            <Scroll />
            <Introduction />
            <KeyPoints />
            <Testimonials />
            <Pricing />
        </>
    );
}