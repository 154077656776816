import "./styles.css";
import KeyPoint from "./KeyPoint";
import { keypoints } from "../../../../config";

export default function KeyPoints() {
    return (
        <section className="keypoints-container">
            {keypoints && keypoints.map((kp, index) => {
                return (
                    <KeyPoint
                        key={index + "-" + kp.title}
                        title={kp.title}
                        body={kp.body}
                    >
                        {kp.icon}
                    </KeyPoint>
                );
            })}
        </section>
    );
}